<template>
  <div
    class="payment-invoice flex-column"
    v-if="billingData && billingData.person"
  >
    <h3>Ti serve la fattura?</h3>
    <v-switch
      v-model="needInvoice"
      inset
      label="Si, voglio la fattura"
      @change="handleInvoiceChange"
    ></v-switch>
    <v-card
      class="payment-invoice-card pa-6"
      v-if="
        (needInvoice && showInvoiceForWarehouse) ||
          (needInvoice && paymentInvoiceEnabledForHD)
      "
      :loading="loading"
    >
      <template slot="progress">
        <v-progress-linear height="10" indeterminate></v-progress-linear>
      </template>
      <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
        @submit.prevent="updateBillingDetails"
      >
        <v-row>
          <v-col cols="12 " class="pb-0">
            <v-radio-group
              v-model="billingData.person.personTypeId"
              mandatory
              row
              @change="validate"
            >
              <v-radio label="PERSONA FISICA" :value="1"></v-radio>
              <v-radio label="SOCIETA'" :value="2"></v-radio>
              <v-radio label="DITTA INDIVIDUALE" :value="3"></v-radio>
              <v-radio label="ENTE NON COMMERCIALE" :value="4"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <template v-if="billingData.person.personTypeId == 1">
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                v-model="billingData.person.firstName"
                label="Nome"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                v-model="billingData.person.lastName"
                label="Cognome"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                v-model="billingData.person.vatFiscalCode"
                label="Codice Fiscale"
                :rules="cfRules"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                v-model="billingData.person.emailCertified"
                label="@PEC"
                :rules="emailRules"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12"
              >Per modificare questi dati vai alla sezione
              <router-link :to="{ name: 'User' }"> profilo </router-link>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                v-model="billingData.email"
                label="Email *"
                :rules="emailPersonRules"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="billingData.person.company"
                label="Ragione sociale"
                :rules="[requiredValue('Ragione sociale')]"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                v-if="billingData.person.personTypeId == 3"
                v-model="billingData.person.vatFiscalCode"
                label="Codice fiscale"
                :rules="cfRules"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-if="
                  billingData.person.personTypeId == 2 ||
                    billingData.person.personTypeId == 4
                "
                v-model="billingData.person.vatFiscalCode"
                label="Codice fiscale"
                outlined
                dense
                required
                :rules="cfIvaRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                v-model="billingData.person.vatCode"
                label="Partita IVA"
                :rules="cfIvaRules"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
              v-if="billingData.person.personTypeId == 4"
            >
              <v-switch
                v-model="billingData.person.splitPayment"
                inset
                label="Split Payment"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-select
                v-model="selectedProvince"
                item-text="Nome"
                item-value="Sigla"
                label="Provincia *"
                :rules="[requiredValue('Provincia obbligatoria')]"
                required
                outlined
                dense
                return-object
                :items="provinceList"
                @change="selectProvince"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                v-model="selectedCity"
                :search-input.sync="city"
                :items="cityList"
                return-object
                item-text="Nome"
                item-value="Nome"
                :disabled="!selectedProvince"
                :rules="[requiredValue('Città obbligatorio')]"
                auto-select-first
                clearable
                required
                outlined
                dense
                hide-no-data
                autocomplete="new_password"
                label="Località *"
                @change="selectCity"
              ></v-autocomplete>
            </v-col>
            <v-col col="8" sm="6" class="pb-0">
              <v-autocomplete
                v-model="selectedAddress"
                :search-input.sync="address1"
                :items="addressList"
                item-text="Nome"
                item-value="Nome"
                :disabled="!selectedCity"
                :rules="[requiredValue('Indirizzo obbligatorio')]"
                auto-select-first
                clearable
                required
                outlined
                dense
                hide-no-data
                autocomplete="new_password"
                label="Indirizzo *"
                @change="selectAddress"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" sm="3" class="pb-0">
              <v-text-field
                v-model="billingData.billingAddress.addressNumber"
                label="Civico"
                :rules="[requiredValue('Civico')]"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" class="pb-0">
              <v-select
                v-model="billingData.billingAddress.postalcode"
                label="Cap *"
                :rules="[requiredValue('CAP obbligatorio')]"
                required
                outlined
                dense
                return-object
                :items="postalcodeList"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                v-model="billingData.person.emailCertified"
                label="@PEC"
                :rules="emailPecRules"
                outlined
                dense
                @blur="$refs.form.validate()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                v-model="billingData.person.vatSdiCode"
                label="Codice destinatario"
                :rules="sdiRules"
                outlined
                dense
                @blur="$refs.form.validate()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                v-model="billingData.email"
                label="Email *"
                :rules="emailPersonRules"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </template>

        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              type="submit"
              large
              color="primary"
              depressed
              :disabled="disabledUpdate"
            >
              SALVA DATI
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <div v-else-if="needInvoice && !showInvoiceForWarehouse">
      <div v-if="serviceId == 1 || serviceId == 10002">
        {{ $t("paymentInvoice.invoicePickupText") }}
      </div>
      <div v-if="serviceId == 2">
        {{ $t("paymentInvoice.invoiceDeliveryText1") }}
        <a href="mailto:fatturaspesaonline@iperal.it">
          {{ $t("paymentInvoice.invoiceDeliveryEmail") }}
        </a>
        {{ $t("paymentInvoice.invoiceDeliveryText2") }}
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.payment-invoice {
  .v-autocomplete {
    .v-input__icon {
      display: none;
    }
  }
}
</style>
<script>
import { mapActions, mapGetters, mapState } from "vuex";

import RegistrationService from "~/service/userService";
import IperalCustomService from "@/service/iperalCustomService";
import { req } from "../../validator/commons";
import find from "lodash/find";
import get from "lodash/get";

import debounce from "lodash/debounce";
import {
  requiredValue,
  isEmail,
  isCF,
  isCfIva
} from "@/validator/validationRules";

export default {
  name: "PaymentInvoice",
  data() {
    return {
      loading: false,
      valid: false,
      lazy: false,
      dirty: false,
      address: null,
      billingData: {},
      requiredValue: requiredValue,
      cfRules: [requiredValue(), isCF()],
      emailPersonRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      emailRules: [
        (this.billingData && !this.billingData.person.emailCertified) ||
          isEmail()
      ],
      emailPecRules: [
        v => {
          if (v) {
            return (
              !req(v) ||
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
              "Digitare una e-mail valida"
            );
          } else if (this.billingData && !this.billingData.person.vatSdiCode) {
            return "PEC o SDI obbligatori";
          } else {
            return true;
          }
        }
      ],
      sdiRules: [
        v => {
          if (v) {
            return /^[A-Z0-9]{6,7}$/.test(v) || "Codice SDI non valido";
          } else if (
            this.billingData &&
            !this.billingData.person.emailCertified
          ) {
            return "PEC o SDI obbligatori";
          } else {
            return true;
          }
        }
      ],
      cfIvaRules: [requiredValue(), isCfIva()],
      provinceList: [],
      selectedProvince: null,
      cityList: [],
      selectedCity: null,
      addressList: [],
      selectedAddress: null,
      postalcodeList: [],
      city: null,
      address1: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      needInvoice: "cart/needInvoice",
      warehouseId: "cart/getWarehouseId",
      serviceId: "cart/getSelectedService"
    }),
    paymentInvoiceEnabledForHD() {
      // in questo caso il core manda una mail al servizio di fatturazione di iperal, se venisse cambiato questo check avvisare pm o core
      let isHomeDelivery = this.serviceId === 2;
      let isLocker = this.serviceId === 10002;
      return (
        (isHomeDelivery || isLocker) &&
        get(this.cart.warehouse, "metaData.warehouse_info.SHOW_INVOICE_FORM")
      );
    },
    showInvoiceForWarehouse() {
      let warehouseId = this.warehouseId;
      return find(global.config.showInvoiceForWarehouseId, o => {
        return o == warehouseId || o == -1;
      });
    },
    disabledUpdate() {
      return !this.valid;
    }
  },
  methods: {
    changed() {
      this.dirty = true;
    },
    validate() {
      let _this = this;
      if (_this.$refs.form) {
        setTimeout(function() {
          _this.valid = _this.$refs.form.validate();
          let isDisabled = !_this.valid && _this.needInvoice;
          _this.$emit("billingFormDisabled", isDisabled);
        }, 200);
      } else {
        _this.$emit("billingFormDisabled", false);
      }
    },
    async updateBillingDetails() {
      let _this = this;
      if (_this.$refs.form.validate()) {
        _this.billingData = await RegistrationService.updateBillingAddress(
          _this.billingData
        );
        _this.dirty = false;
        //_this.getUserDetail();
        _this.validate();
      }
    },
    async getUserDetail() {
      let _this = this;
      let res = await RegistrationService.getUserDetail();
      _this.billingData = res;
    },
    async fetchProvinceList() {
      let _this = this;
      _this.provinceList = await IperalCustomService.getPosteProvinceList();
    },
    selectProvince(province) {
      let _this = this;
      if (province.Id) {
        _this.billingData.billingAddress.province = province.Sigla;
        _this.resetCity();
        _this.resetAddress();
      }
    },
    async getCityList(filter, idProvince) {
      let _this = this;
      _this.cityList = await IperalCustomService.getPosteCityList(
        filter,
        idProvince
      );
    },
    fetchCityList: debounce(function(val) {
      let _this = this;
      this.getCityList(val, _this.selectedProvince.Id);
    }, 400),
    selectCity(city) {
      let _this = this;
      if (city.Id != null) {
        _this.billingData.billingAddress.city = city.Nome;
        _this.resetAddress();
      } else if (this.cityList.length > 0) {
        _this.selectedCity = this.cityList[0];
        this.selectCity(_this.selectedCity);
      }
    },
    resetCity() {
      let _this = this;
      _this.selectedCity = null;
      _this.billingData.billingAddress.city = null;
      _this.cityList = [];
    },
    async getAddressList(address, cityId) {
      this.addressList = await IperalCustomService.getPosteAddressList(
        address,
        cityId
      );
    },
    selectAddress(address) {
      let _this = this;
      if (address.Id) {
        this.billingData.billingAddress.address1 = address.Nome;
        this.postalcodeList = address.elencoCAP;
        if (address.elencoCAP.length == 1) {
          this.billingData.billingAddress.postalcode = address.elencoCAP[0];
        }
      } else if (this.addressList.length > 0) {
        _this.selectedAddress = this.addressList[0];
        this.selectAddress(this.addressList[0]);
      }
    },
    resetAddress() {
      let _this = this;
      _this.selectedAddress = null;
      _this.addressList = [];
      _this.billingData.billingAddress.address1 = null;
    },
    fetchAddressSuggestion: debounce(function(val) {
      this.getAddressList(val, this.selectedCity.Id);
    }, 400),

    async handleInvoiceChange() {
      let _this = this;
      await _this.setCartInfo({ need_invoice: !_this.needInvoice });
      if (_this.needInvoice) {
        await _this.fetchDefaultData();
      }
      _this.validate();
    },
    async fetchDefaultData() {
      let _this = this;
      try {
        _this.loading = true;
        await _this.fetchProvinceList();
        if (_this.billingData.billingAddress.province) {
          _this.selectedProvince = _this.provinceList.find(
            p =>
              p.Sigla.toUpperCase() ===
              _this.billingData.billingAddress.province.toUpperCase()
          );
          if (_this.selectedProvince) {
            await _this.getCityList(
              _this.billingData.billingAddress.city,
              _this.selectedProvince.Id
            );
            _this.selectedCity = _this.cityList.find(
              p =>
                p.Nome.toUpperCase() ===
                _this.billingData.billingAddress.city.toUpperCase()
            );
            //continua con l'indirizzo
            if (_this.selectedCity) {
              await _this.getAddressList(
                _this.billingData.billingAddress.address1,
                _this.selectedCity.Id
              );
              _this.selectedAddress =
                _this.addressList.length == 1
                  ? _this.addressList[0]
                  : _this.addressList.find(
                      p =>
                        p.Nome.toUpperCase() ===
                        _this.billingData.billingAddress.address1.toUpperCase()
                    );
              _this.postalcodeList = _this.selectedAddress.elencoCAP;
            }
          }
        }
      } finally {
        _this.loading = false;
      }
    },
    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    })
  },
  watch: {
    address1(val, oldVal) {
      if (val && val.trim().length > 3 && val != oldVal) {
        this.fetchAddressSuggestion(val);
      }
    },
    city(val, oldVal) {
      if (val && val.trim().length > 0 && val != oldVal) {
        this.fetchCityList(val, this.provinceId);
      }
    }
  },
  async mounted() {
    await this.getUserDetail();
    if (this.needInvoice) {
      await this.fetchDefaultData();
      this.validate();
    }
  }
};
</script>
