<template>
  <div class="payment d-flex flex-column scroll-container">
    <v-container v-if="getProposals('header-banner')">
      <ProposalSlider :proposals="getProposals('header-banner')" />
    </v-container>
    <v-container class="d-flex flex-column scroll-container">
      <CategoryTitle :category="category" />
      <v-row>
        <v-col cols="12" md="8" order="last" order-sm="first">
          <PaymentInvoice @billingFormDisabled="billingFormDisabled" />
          <!-- <PackagesTypeList :packages="packagesList" /> -->

          <PaymentTypeList
            :cart="cart"
            mode="cart"
            :isPaymentDisabled="isPaymentDisabled"
            :options="paymentTypeListOptions"
          />
          <RecaptchaDisclaimer @click="clicked" />
        </v-col>
        <!-- :cartInfos="cartInfos" -->
        <v-col
          cols="12"
          md="4"
          order="first"
          order-sm="last"
          v-if="cart.shippingAddress"
        >
          <CartInfoAddressCard
            :shippingAddress="cart.shippingAddress"
            :editable="editableAddress"
            @edit="openAddressSelector"
          />
          <CartInfoTimeslotCard
            :shippingAddress="cart.shippingAddress"
            :timeslot="cart.timeslot"
            :editable="editableTimeslot"
            @edit="openTimeslotSelector"
          />

          <CheckoutSummary :orderCart="cart" />
        </v-col>
      </v-row>
    </v-container>
    <v-spacer class="spacer-scroll"></v-spacer>
    <v-container v-if="getProposals('footer-banner')">
      <ProposalSlider :proposals="getProposals('footer-banner')" />
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.payment-methods {
  background-color: transparent !important;
}
::v-deep .payment-auth-list strong {
  font-weight: 400;
  padding: 0 16px 0 16px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  ::v-deep .remember-card-container {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  ::v-deep .remember-card-container button {
    margin-right: 16px;
  }
}
</style>
<script>
import CartService from "~/service/cartService";
import AnalyticsService from "~/service/analyticsService";
import CategoryTitle from "@/components/category/CategoryTitle";
import CheckoutSummary from "@/components/cart/CheckoutSummary";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard";
import RecaptchaDisclaimer from "@/commons/components/RecaptchaDisclaimer.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";
// import PackagesTypeList from "@/components/payment/PackagesTypeList";
import PaymentInvoice from "@/components/payment/PaymentInvoice";
import ProposalSlider from "@/components/proposal/ProposalSlider";
import { mapState, mapGetters } from "vuex";
import login from "~/mixins/login";
import productMixins from "~/mixins/product";
import cartInfo from "~/mixins/cartInfo";
import clickHandler from "~/mixins/clickHandler";
import categoryMixins from "~/mixins/category";
// import RegistrationService from "~/service/userService";

export default {
  name: "Payment",
  data() {
    return {
      paymentTypeList: [],
      packagesList: [],
      isPaymentDisabled: false,
      paymentTypeListOptions: {
        showAddNewCardWarning: false,
        flatPanels: true,
        showCardLogos: true,
        titleStyle: "default--text font-weight-bold text-h6",
        back: {
          label: "Indietro",
          class:
            "bg-white v-btn v-btn--outlined theme--light v-size--x-large primary--text",
          icon: null,
          link: true,
          show: true
        },
        confirm: {
          class: "v-size--x-large"
        }
      }
    };
  },
  components: {
    PaymentTypeList,
    CategoryTitle,
    CheckoutSummary,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    // PackagesTypeList,
    PaymentInvoice,
    ProposalSlider,
    RecaptchaDisclaimer
  },
  created() {
    this.fetchPackages();

    AnalyticsService.checkoutProgress(this.cart);

    // this.getUserDetails();
  },
  mixins: [login, productMixins, cartInfo, categoryMixins, clickHandler],

  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      getCartStatus: "cart/getCartStatus"
    })
  },
  methods: {
    billingFormDisabled(isDisabled) {
      this.isPaymentDisabled = isDisabled;
    },

    fetchPackages() {
      CartService.getPackages().then(packagesList => {
        if (packagesList) {
          this.packagesList = packagesList;

          this.packagesList = this.packagesList.concat([
            {
              available: 10,
              productId: 0,
              name: "Nessun sacchetto",
              shortDescr: "",
              description: "",
              mediaURL: "",
              highMediaURL: ""
            }
          ]);
        }
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.cart && vm.cart.totalItems > 0) {
        next();
      } else {
        if (localStorage.currentOrderId) {
          //vado al dettaglio dell'ordine
          let orderId = localStorage.currentOrderId;
          next("/profile/orders/" + orderId);
        } else {
          next("/");
        }
      }
    });
  }
};
</script>
