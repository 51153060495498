<template>
  <v-card
    rounded="lg"
    :aria-label="proposal.slug"
    :href="link"
    @click.prevent="handleLink"
    elevation="2"
    class="proposal-img"
  >
    <v-img :src="src" :alt="proposal.name" />
  </v-card>
</template>
<script>
import banner from "~/mixins/banner";
import get from "lodash/get";
export default {
  name: "ProposalImage",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner],
  computed: {
    src() {
      let prop =
        "metaData.category_proposal_type." +
        (!this.$vuetify.breakpoint.xsOnly ? "IMAGE_DESKTOP" : "IMAGE_MOBILE");
      return get(this.proposal, prop, this.proposal.img);
    }
  }
};
</script>
